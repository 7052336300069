import LogoutButton from "./LogoutButton";
import { localisedRouteGenerator } from "../../i18n/routes/routeGenerator.ts";
import { FormattedMessage, useIntl } from "react-intl";

export default function Footer() {
  const intl = useIntl();
  return (
    <footer>
      <div className="p-4 bg-white md:flex md:items-center md:justify-between md:p-6">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2025{" "}
          <a href="https://ashtonspina.com/" className="hover:underline">
            TechTactics Oy
          </a>
          &nbsp;All rights reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <a
              href={localisedRouteGenerator(false, intl, "route.business")}
              className="mr-4 hover:underline md:mr-6 "
            >
              <FormattedMessage
                id="footer.business"
                description="Link to the business page from the footer"
                defaultMessage="About our Business"
              />
            </a>
          </li>
          <li>
            <a
              href={localisedRouteGenerator(false, intl, "route.privacy")}
              className="mr-4 hover:underline md:mr-6"
            >
              <FormattedMessage
                id="footer.privacy"
                description="Link to the privacy policy page from the footer"
                defaultMessage="Privacy Policy"
              />
            </a>
          </li>
          <li>
            <a
              href={localisedRouteGenerator(false, intl, "route.terms")}
              className="hover:underline"
            >
              <FormattedMessage
                id="footer.terms"
                description="Link to the terms of service page from the footer"
                defaultMessage="Terms of Service"
              />
            </a>
          </li>
          <li>
            <LogoutButton />
          </li>
        </ul>
      </div>
      <div className="p-4 bg-white md:flex md:items-center md:justify-center gap-4 md:p-6">
        <ul className="flex flex-col items-center mt-3 text-sm text-gray-500 dark:text-gray-400">
          <li>Jobs in Helsinki</li>
          <li>Jobs in Tampere</li>
          <li>Jobs in Oulu</li>
          <li>Jobs in Turku</li>
          <li>Jobs in Joensuu</li>
          <li>Remote Jobs</li>
        </ul>
        <ul className="flex flex-col items-center mt-3 text-sm text-gray-500 dark:text-gray-400">
          <li>English Jobs</li>
          <li>Finnish Jobs</li>
          <li>Swedish Jobs</li>
        </ul>
        <ul className="flex flex-col items-center mt-3 text-sm text-gray-500 dark:text-gray-400">
          <li>Intern Jobs</li>
          <li>Entry Level Jobs</li>
          <li>Junior Jobs</li>
          <li>Management Jobs</li>
          <li>Executive Jobs</li>
        </ul>
        <ul className="flex flex-col items-center mt-3 text-sm text-gray-500 dark:text-gray-400">
          <li>Real Estate Jobs</li>
          <li>Tech Jobs</li>
          <li>Media Jobs</li>
          <li>Restaurant Jobs</li>
          <li>Hotel Jobs</li>
        </ul>
      </div>
    </footer>
  );
}
