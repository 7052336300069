import "instantsearch.css/themes/satellite.css";
import { SearchWrapper } from "../components/SearchWrapper";
import HomeResults from "./HomeResults.tsx";

export default function Home() {
  return (
    <SearchWrapper>
      <HomeResults />
    </SearchWrapper>
  );
}
