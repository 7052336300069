import "instantsearch.css/themes/satellite.css";
import { Hits, Configure, Pagination, Stats } from "react-instantsearch";
import { SearchWrapper } from "../components/SearchWrapper";
import { QuickSearchBanner } from "../components/QuickSearchBanner";
import { SEOText } from "../components/SEOText";
import { Hit } from "../components/Hit";
import { getSavedJobs } from "../components/actions/getSavedJobs";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import Footer from "../components/components/Footer.tsx";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export default function SavedJobs() {
  const intl = useIntl();
  const savedJobsFilterArray = getSavedJobs()
    .map((job) => `objectID:"${job}"`)
    .join(" OR ");

  return (
    <SearchWrapper>
      <>
        <Helmet>
          <title>
            {`JobCrawls.com | ${intl.formatMessage({
              id: "route.title.savedJobs",
              defaultMessage: "Saved Jobs",
              description: "The saved jobs page title",
            })}`}
          </title>
        </Helmet>
        <div className="bg-white dark:bg-gray-700">
          <Configure hitsPerPage={25} filters={savedJobsFilterArray} />
          {/* TODO:: Setup bread crumbs to follow the job hierarchy so people can go back */}
          {/*<Breadcrumb*/}
          {/*  attributes={["categories.lvl0", "categories.lvl1", "categories.lvl2"]}*/}
          {/*/>*/}
          {/* TODO:: create sorts so people can sort by common sorts */}
          <div className="w-full px-0 lg:px-12 bg-white dark:bg-gray-700 pt-32 xl:pt-20 m-auto max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-5xl">
            {/*<SortBy*/}
            {/*  items={[*/}
            {/*    { label: 'Featured', value: 'text_search_index' },*/}
            {/*    { label: 'Date Posted (desc )', value: 'text_search_index_posted_date_desc' },*/}
            {/*    { label: 'Application Date (asc)', value: 'text_search_index_application_date_asc' },*/}
            {/*  ]}*/}
            {/*/>*/}
            <SEOText />
            <QuickSearchBanner />
            <div className="ais-InstantSearch w-full bg-white dark:bg-gray-700 m-auto max-w-5xl">
              <div className="text-black dark:text-white pl-4">
                <Stats />
              </div>
              {/* TODO:: Change hit component class so it can show dark and light */}
              <Hits hitComponent={Hit} />
            </div>
            <div className="w-full pt-0 lg:pt-8 pb-8 items-center justify-center ml-0 md:ml-8 lg:ml-16 xl:ml-32">
              {/* TODO:: Find a way to center this */}
              <Pagination padding={isMobile ? 1 : 3} />
            </div>
          </div>
        </div>
      </>
      <Footer />
    </SearchWrapper>
  );
}
