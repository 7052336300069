import { FunctionComponent, ReactElement, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import {
  ClearRefinements,
  DynamicWidgets,
  InstantSearch,
  RefinementList,
  useInstantSearch,
} from "react-instantsearch";
import { RangeSlider } from "./RangeSlider";
import Container from "./components/Container";
import Logo from "./components/navbar/Logo";
import UserMenu from "./components/navbar/UserMenu";
import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import algoliasearch from "algoliasearch";
import ReactGA from "react-ga4";
import { localisedRouteGenerator } from "../i18n/routes/routeGenerator";
import { useNavigate } from "react-router-dom";
import { routing } from "../generateURLs/generateURLs";
import { Autocomplete } from "./Autocomplete";

const searchClient = algoliasearch(
  "B4Y7NL266A",
  "b4d9f069ed4a8e2a460a83f900f0eead"
);

const getIndexName = (locale: string) => {
  if (locale === "fi") {
    return "fi_hae";
  } else if (locale === "sv") {
    return "sv_sok";
  } else {
    return "en_search";
  }
};

interface SearchWrapperProps extends React.PropsWithChildren {
  children: ReactElement;
}

export const SearchWrapper: FunctionComponent<SearchWrapperProps> = (props) => {
  //TODO:: testing custom search box so I can custom set it's value

  const intl: IntlShape = useIntl();
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [showLocationFilters, setShowLocationFilters] = useState(false);
  const [showSalaryFilters, setShowSalaryFilters] = useState(false);
  const [showCompanyFilters, setShowCompanyFilters] = useState(false);
  const [showJobFilters, setShowJobFilters] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // TODO:: Make urls more SEO Friendly
  // TODO:: https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/#basic-urls
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={getIndexName(intl.locale)}
      // routing={routing(navigate)}
      routing={true}
      insights={true}
    >
      <div
        onClick={(event) => {
          // This will make it so if the background is clicked the modal will close
          if (event.target === event.currentTarget) {
            setShowModal(false);
            setShowLocationFilters(false);
            setShowSalaryFilters(false);
            setShowCompanyFilters(false);
            setShowJobFilters(false);
          }
        }}
        className={`
          justify-center
          items-center
          flex
          overflow-x-hidden
          overflow-y-auto
          fixed
          inset-0
          z-50
          outline-none
          focus:outline-none
          bg-neutral-800/70
          ${showModal ? "" : "hidden"}
        `}
      >
        <div
          className="
            relative
            w-full
            md:w-4/6
            lg:w-3/6
            xl:w-2/5
            my-6
            mx-auto
            h-full
            lg:h-auto
            md:h-auto
          "
        >
          <div
            className={`
              translate
              duration-300
              h-full
              ${showModal ? "translate-y-0" : "translate-y-full"}
              ${showModal ? "opacity-100" : "opacity-0"}
            `}
          >
            <div
              className="
            translate
            h-full
            lg:h-auto
            md:h-auto
            border-0
            rounded-lg
            shadow-lg
            relative
            flex
            flex-col
            w-full
            outline-none
            focus:outline-none
            bg-white
            dark:bg-gray-700
          "
            >
              <div
                className="
                bg-amber-300
              flex
              items-center
              p-6
              rounded-t
              justify-center
              relative
              border-b-[1px]
              "
              >
                <button
                  className="
                  p-1
                  border-0
                  hover:opacity-70
                  transition
                  absolute
                  left-9
                "
                  onClick={() => {
                    setShowModal(false);
                    setShowLocationFilters(false);
                    setShowSalaryFilters(false);
                    setShowCompanyFilters(false);
                    setShowJobFilters(false);
                  }}
                >
                  <IoMdClose
                    className="text-black dark:text-gray-200"
                    size={18}
                  />
                </button>
                {/*TODO: Add translations, add other titles of modals */}
                <div className="text-lg font-semibold text-black dark:text-gray-200">
                  {showLocationFilters ? (
                    <FormattedMessage
                      id="search.refinements.location.modal"
                      description="Heading for the location filter modal"
                      defaultMessage="Location Filters"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="text-lg font-semibold text-black dark:text-gray-200">
                  {showSalaryFilters ? (
                    <FormattedMessage
                      id="search.refinements.salary.modal"
                      description="Heading for the salary filter modal"
                      defaultMessage="Salary Filters"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="text-lg font-semibold text-black dark:text-gray-200">
                  {showCompanyFilters ? (
                    <FormattedMessage
                      id="search.refinements.company.modal"
                      description="Heading for the company filter modal"
                      defaultMessage="Company Filters"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="text-lg font-semibold text-black dark:text-gray-200">
                  {showJobFilters ? (
                    <FormattedMessage
                      id="search.refinements.job.modal"
                      description="Heading for the job filter modal"
                      defaultMessage="Job Filters"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/*body*/}
              <div className="flex items-center justify-center">
                <div
                  className={`bg-white divide-y divide-gray-100 dark:bg-gray-700 ${
                    showLocationFilters ? "" : "hidden"
                  }`}
                >
                  <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                    <li>
                      <FormattedMessage
                        id={"search.refinements.location"}
                        defaultMessage={"Location"}
                      />
                      <DynamicWidgets>
                        <RefinementList attribute="location" />
                      </DynamicWidgets>
                    </li>
                    <li>
                      <ClearRefinements
                        translations={{
                          resetButtonText: "Clear All",
                        }}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className={`bg-white divide-y divide-gray-100 dark:bg-gray-700 text-black dark:text-gray-200 ${
                    showSalaryFilters ? "" : "hidden"
                  }`}
                >
                  <FormattedMessage
                    id={"search.refinements.salary"}
                    defaultMessage={"Salary"}
                  />
                  <ul
                    className="py-2 text-sm text-black dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    {/* TODO:: Change this to show a slider */}
                    <li>
                      <DynamicWidgets>
                        <RangeSlider attribute="salaryRange.min" />
                      </DynamicWidgets>
                    </li>
                    <li>
                      <ClearRefinements
                        translations={{
                          resetButtonText: "Clear All",
                        }}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className={`bg-amber:3 divide-y divide-gray-100 dark:bg-gray-700 ${
                    showCompanyFilters ? "" : "hidden"
                  }`}
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <FormattedMessage
                        id={"search.refinements.industry"}
                        defaultMessage={"Industry"}
                      />
                      <DynamicWidgets>
                        <RefinementList attribute="company.industry" />
                      </DynamicWidgets>
                    </li>
                    <li>
                      <FormattedMessage
                        id={"search.refinements.company"}
                        defaultMessage={"Company"}
                      />
                      <DynamicWidgets>
                        <RefinementList attribute="companyId" />
                      </DynamicWidgets>
                    </li>
                    <li>
                      <ClearRefinements
                        translations={{
                          resetButtonText: "Clear All",
                        }}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className={`bg-white divide-y divide-gray-100 dark:bg-gray-700 ${
                    showJobFilters ? "" : "hidden"
                  }`}
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <FormattedMessage
                        id={"search.refinements.remoteness"}
                        defaultMessage={"Remoteness"}
                      />
                      <DynamicWidgets>
                        <RefinementList attribute="remoteness" />
                      </DynamicWidgets>
                    </li>
                    <li>
                      <FormattedMessage
                        id={"search.refinements.roleLevel"}
                        defaultMessage={"Role Level"}
                      />
                      <DynamicWidgets>
                        <RefinementList attribute="normalisedRoleLevel" />
                      </DynamicWidgets>
                    </li>
                    <li>
                      <FormattedMessage
                        id={"search.refinements.languages"}
                        defaultMessage={"Languages"}
                      />
                      <DynamicWidgets>
                        <RefinementList
                          attribute="requiredLanguages"
                          operator="and"
                        />
                      </DynamicWidgets>
                    </li>
                    <li>
                      <ClearRefinements
                        translations={{
                          resetButtonText: "Clear All",
                        }}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed w-full z-20 shadow-sm bg-amber-300 text-black">
        <div className="border-b-[1px] dark:border-slate-400 pb-2 xl:pb-0">
          <Container>
            <div className="flex flex-row items-center justify-between gap-1 sm:gap-3">
              <Logo />
              <div className="flex flex-col sticky bg-amber-300 w-full">
                <div className="flex justify-center items-center w-full">
                  <div className="flex flex-col w-full max-w-[2000px] bg-amber-300 text-black">
                    <div className="flex flex-col xl:flex-row xl:items-start xl:space-x-4 w-full pt-2 xl:pt-4 md:px-8 xl:px-16 bg-amber-300">
                      <div className="w-full xl:max-w-xs flex-none bg-amber-300">
                        <div className="flex flex-row sm:flex-col w-full space-y-1 pb-2 bg-amber-300">
                          <img
                            onClick={() =>
                              navigate(
                                localisedRouteGenerator(
                                  false,
                                  intl,
                                  "route.home"
                                )
                              )
                            }
                            className="block sm:hidden cursor-pointer"
                            src={"/logo_square.png"}
                            height="50"
                            width="50"
                            alt="Logo"
                          />
                          <Autocomplete
                            placeholder={intl.formatMessage({
                              id: "instantsearch.searchbox.placeholder",
                              description: "Placeholder for the search box",
                              defaultMessage: "Search for jobs",
                            })}
                            detachedMediaQuery="none"
                            openOnFocus
                            searchClient={searchClient}
                          />
                        </div>
                      </div>
                      <div className="flex items-center md:space-x-3 w-full">
                        <div className="flex items-center space-x-1 sm:space-x-2 md:space-x-3 text-black font-medium w-fit overflow-x-auto scrollbar-hide">
                          <div className="flex-none bg-amber-300 dark:bg-gray-700 text-black dark:text-gray-200">
                            <button
                              className="bg-white flex outline-none space-x-0 lg:space-x-2 items-center flex-none rounded px-3 py-2 font-bold text-sm border border-slate-400 hover:border-blue-400 hover:bg-blue-50/40"
                              onClick={() => {
                                ReactGA.event({
                                  category: "interactions",
                                  action: "clickLocationModal",
                                  nonInteraction: false, // optional, true/false
                                });
                                setShowLocationFilters(true);
                                setShowModal(true);
                              }}
                            >
                              <span className="flex-none">
                                <FormattedMessage
                                  id={"search.refinements.location"}
                                  defaultMessage={"Location"}
                                />
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                                className="w-4 h-4 flex-none hidden sm:block"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                ></path>
                              </svg>
                            </button>
                          </div>
                          <div className="flex-none bg-amber-300 dark:bg-gray-700 text-black dark:text-gray-200">
                            <button
                              className="bg-white flex outline-none space-x-0 lg:space-x-2 items-center flex-none rounded px-3 py-2 font-bold text-sm border border-slate-400 hover:border-blue-400 hover:bg-blue-50/40"
                              onClick={() => {
                                ReactGA.event({
                                  category: "interactions",
                                  action: "clickSalaryModal",
                                  nonInteraction: false, // optional, true/false
                                });
                                setShowSalaryFilters(true);
                                setShowModal(true);
                              }}
                            >
                              <span className="flex-none">
                                <FormattedMessage
                                  id={"search.refinements.salary"}
                                  defaultMessage={"Salary"}
                                />
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                                className="w-4 h-4 flex-none hidden sm:block"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                ></path>
                              </svg>
                            </button>
                          </div>
                          <div className="bg-amber-300 flex-none dark:bg-gray-700 text-black dark:text-gray-200">
                            <button
                              className="bg-white flex outline-none space-x-0 lg:space-x-2 items-center flex-none rounded px-3 py-2 font-bold text-sm border border-slate-400 hover:border-blue-400 hover:bg-blue-50/40"
                              onClick={() => {
                                ReactGA.event({
                                  category: "interactions",
                                  action: "clickCompanyModal",
                                  nonInteraction: false, // optional, true/false
                                });
                                setShowCompanyFilters(true);
                                setShowModal(true);
                              }}
                            >
                              <span className="flex-none">
                                <FormattedMessage
                                  id={"search.refinements.company"}
                                  defaultMessage={"Company"}
                                />
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                                className="w-4 h-4 flex-none hidden sm:block"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                ></path>
                              </svg>
                            </button>
                          </div>
                          <div className="bg-amber-300 flex-none dark:bg-gray-700 text-black dark:text-gray-200">
                            <button
                              className="bg-white flex outline-none space-x-0 lg:space-x-2 items-center flex-none rounded px-3 py-2 font-bold text-sm border border-slate-400 hover:border-blue-400 hover:bg-blue-50/40"
                              onClick={() => {
                                ReactGA.event({
                                  category: "interactions",
                                  action: "clickJobModal",
                                  nonInteraction: false, // optional, true/false
                                });
                                setShowJobFilters(true);
                                setShowModal(true);
                              }}
                            >
                              <span className="flex-none">
                                <FormattedMessage
                                  id={"search.refinements.job"}
                                  defaultMessage={"Job"}
                                />
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                                className="w-4 h-4 flex-none hidden sm:block"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <UserMenu currentUser={user} />
            </div>
          </Container>
        </div>
      </div>
      {props.children}
    </InstantSearch>
  );
};
