import { IntlShape, useIntl, FormattedMessage } from "react-intl";
import { SearchWrapper } from "../components/SearchWrapper";
import { Link } from "react-router-dom";
import { localisedRouteGenerator } from "../i18n/routes/routeGenerator";
import { Helmet } from "react-helmet-async";
import Footer from "../components/components/Footer.tsx";

export default function Blog() {
  const intl: IntlShape = useIntl();
  return (
    <SearchWrapper>
      <main className="max-w-4xl mx-auto py-8 px-4 pt-20">
        <Helmet>
          <title>
            {`JobCrawls.com | ${intl.formatMessage({
              id: "route.title.blog",
              defaultMessage: "Blog",
              description: "The blog page title",
            })}`}
          </title>
          <meta
            name="description"
            content={intl.formatMessage({
              id: "route.description.blog",
              defaultMessage:
                "Explore the latest insights, tips, and stories in Finnish jobs and beyond.",
              description: "The blog page description",
            })}
          />
          <meta
            name="keywords"
            content={intl.formatMessage({
              id: "route.keywords.blog",
              defaultMessage: "blog, jobs, finland, tech jobs, english jobs",
              description: "The blog page keywords",
            })}
          />
        </Helmet>
        {/*<header className="bg-amber-400 py-6">*/}
        {/*  <div className="max-w-4xl mx-auto px-4">*/}
        {/*    <h1 className="text-3xl font-bold">Welcome to Our Blog</h1>*/}
        {/*    <p className="text-lg mt-2">*/}
        {/*      Explore the latest insights, tips, and stories in Finnish jobs and*/}
        {/*      beyond.*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</header>*/}

        <article className="bg-white rounded-lg shadow-md mb-6 overflow-hidden">
          <img
            src="https://images.unsplash.com/photo-1522885147691-06d859633fb8?q=80&w=800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Blog Image"
            className="w-full h-48 object-cover"
          />
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-2">
              Top Tech Companies in Finland Working in English
            </h2>
            <p className="text-gray-700 mb-4">
              Discover the coolest tech companies in Finland like Yousician,
              Saidot, Wolt, Supercell, Rovio, Awake.ai, and Skyforce that
              embrace English as their working language.
            </p>
            <Link
              className="text-blue-600 font-semibold hover:underline"
              to={localisedRouteGenerator(
                false,
                intl,
                "route.coolFinnishTechCompaniesEnglish"
              )}
            >
              Read More &rarr;
            </Link>
          </div>
        </article>

        {/*<article className="bg-white rounded-lg shadow-md mb-6 overflow-hidden">*/}
        {/*  <img src="https://via.placeholder.com/800x400" alt="Blog Image" className="w-full h-48 object-cover"/>*/}
        {/*  <div className="p-6">*/}
        {/*    <h2 className="text-2xl font-bold text-blue-600 mb-2">Another Blog Post Title</h2>*/}
        {/*    <p className="text-gray-700 mb-4">*/}
        {/*      A brief intro to the blog post to spark curiosity and enhance SEO value for your website.*/}
        {/*    </p>*/}
        {/*    <a href="#" className="text-blue-600 font-semibold hover:underline">Read More &rarr;</a>*/}
        {/*  </div>*/}
        {/*</article>*/}

        {/*<article className="bg-white rounded-lg shadow-md mb-6 overflow-hidden">*/}
        {/*  <img src="https://via.placeholder.com/800x400" alt="Blog Image" className="w-full h-48 object-cover"/>*/}
        {/*  <div className="p-6">*/}
        {/*    <h2 className="text-2xl font-bold text-blue-600 mb-2">Yet Another Article Title</h2>*/}
        {/*    <p className="text-gray-700 mb-4">*/}
        {/*      Another engaging intro for SEO-friendly blog content designed for tech-savvy readers.*/}
        {/*    </p>*/}
        {/*    <a href="#" className="text-blue-600 font-semibold hover:underline">Read More &rarr;</a>*/}
        {/*  </div>*/}
        {/*</article>*/}
      </main>
      <Footer />
    </SearchWrapper>
  );
}
