import { SearchWrapper } from "../components/SearchWrapper";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import Footer from "../components/components/Footer.tsx";
import { getSavedSearches } from "../components/actions/getSavedSearches.ts";
import { Link } from "react-router-dom";

export default function SavedSearches() {
  const intl = useIntl();
  const savedSearches = getSavedSearches();

  return (
    <SearchWrapper>
      <>
        <Helmet>
          <title>
            {`JobCrawls.com | ${intl.formatMessage({
              id: "route.title.savedSearches",
              defaultMessage: "Saved Searches",
              description: "The saved searches page title",
            })}`}
          </title>
        </Helmet>
        <div className="bg-white dark:bg-gray-700">
          {savedSearches.map((path) => (
            <Link to={path} />
          ))}
        </div>
        <Footer />
      </>
    </SearchWrapper>
  );
}
