import "instantsearch.css/themes/satellite.css";
import {
  Hits,
  Configure,
  Pagination,
  Stats,
  useInstantSearch,
} from "react-instantsearch";
import { SearchWrapper } from "../components/SearchWrapper";
import { QuickSearchBanner } from "../components/QuickSearchBanner";
import { SEOText } from "../components/SEOText";
import { Hit } from "../components/Hit";
import { Helmet } from "react-helmet-async";
import { changeLanguage } from "../generateURLs/helpers.ts";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Footer from "../components/components/Footer.tsx";
import { getSavedSearches } from "../components/actions/getSavedSearches.ts";
import { searchTree } from "../searchTree.tsx";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

function generateSeoTitle(
  location: string[],
  remoteness: string[],
  roleLevel: string[],
  languages: string[],
  industry: string[],
  company: string[],
  locale: string
): string {
  // Join each array into a comma-separated string if it has values
  const locStr = location.length ? location.join(", ") : "";
  const remStr = remoteness.length ? remoteness.join(", ") : "";
  const levelStr = roleLevel.length ? roleLevel.join(", ") : "";
  const langsStr = languages.length ? languages.join(", ") : "";
  const indStr = industry.length ? industry.join(", ") : "";
  const compStr = company.length ? company.join(", ") : "";

  let jobSegment: string;

  // Build the title based on locale-specific formatting rules.
  if (locale === "en") {
    // Example: "Senior English Tech Jobs at Acme Inc in Helsinki (Remote)"
    const titleParts: string[] = [];
    if (levelStr) titleParts.push(levelStr);
    if (langsStr) titleParts.push(langsStr);
    if (indStr) titleParts.push(indStr);
    jobSegment = titleParts.length ? `${titleParts.join(" ")} Jobs` : "Jobs";
    if (compStr) jobSegment += ` at ${compStr}`;
    if (locStr) jobSegment += ` in ${locStr}`;
    if (remStr) jobSegment += ` (${remStr})`;
  } else if (locale === "fi") {
    // Example: "Senior Englanti Tech Työt yrityksessä Acme Inc, Helsinki (Etä)"
    const titleParts: string[] = [];
    if (levelStr) titleParts.push(levelStr);
    if (langsStr) titleParts.push(langsStr);
    if (indStr) titleParts.push(indStr);
    jobSegment = titleParts.length ? `${titleParts.join(" ")} Työt` : "Työt";
    if (compStr) jobSegment += ` yrityksessä ${compStr}`;
    if (locStr) jobSegment += ` ${locStr}`;
    if (remStr) jobSegment += ` (${remStr})`;
  } else if (locale === "sv") {
    // Example: "Senior Engelska Tech Jobb på Acme Inc i Stockholm (Distans)"
    const titleParts: string[] = [];
    if (levelStr) titleParts.push(levelStr);
    if (langsStr) titleParts.push(langsStr);
    if (indStr) titleParts.push(indStr);
    jobSegment = titleParts.length ? `${titleParts.join(" ")} Jobb` : "Jobb";
    if (compStr) jobSegment += ` på ${compStr}`;
    if (locStr) jobSegment += ` i ${locStr}`;
    if (remStr) jobSegment += ` (${remStr})`;
  } else {
    // Fallback to English if locale is not recognized.
    const titleParts: string[] = [];
    if (levelStr) titleParts.push(levelStr);
    if (langsStr) titleParts.push(langsStr);
    if (indStr) titleParts.push(indStr);
    jobSegment = titleParts.length ? `${titleParts.join(" ")} Jobs` : "Jobs";
    if (compStr) jobSegment += ` at ${compStr}`;
    if (locStr) jobSegment += ` in ${locStr}`;
    if (remStr) jobSegment += ` (${remStr})`;
  }

  // Append the site name for branding.
  return `${jobSegment} | JobCrawls.com`;
}

export default function HomeResults() {
  const intl = useIntl();

  const { indexUiState, setIndexUiState } = useInstantSearch();
  const refinementsLocation = indexUiState?.refinementList?.location ?? [];
  const refinementsRoleLevel = indexUiState?.refinementList?.roleLevel ?? [];
  const refinementsRemoteness = indexUiState?.refinementList?.remoteness ?? [];
  const refinementsLanguages =
    indexUiState?.refinementList?.requiredLanguages ?? [];
  const refinementsIndustry =
    indexUiState?.refinementList?.["company.industry"] ?? [];
  const refinementsCompany = indexUiState?.refinementList?.companyId ?? [];

  let title = generateSeoTitle(
    refinementsLocation,
    refinementsRemoteness,
    refinementsRoleLevel,
    refinementsLanguages,
    refinementsIndustry,
    refinementsCompany,
    intl.locale
  );
  const [isSearchSaved, setIsSearchSaved] = useState(
    getSavedSearches()?.includes(location.pathname)
  );

  useEffect(() => {
    title = generateSeoTitle(
      refinementsLocation,
      refinementsRemoteness,
      refinementsRoleLevel,
      refinementsLanguages,
      refinementsIndustry,
      refinementsCompany,
      intl.locale
    );
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <html lang={intl.locale} />
        <title>{title}</title>
        {title && <meta name="description" content={title} />}
        {<meta name="keywords" content={title.split(" ").join(", ")} />}
        {/*<link rel="canonical" href={window.location.href} />*/}
        <link
          rel="alternate"
          hrefLang="en"
          href={changeLanguage(intl.locale, "en", window.location)}
        />
        <link
          rel="alternate"
          hrefLang="fi"
          href={changeLanguage(intl.locale, "fi", window.location)}
        />
        <link
          rel="alternate"
          hrefLang="sv"
          href={changeLanguage(intl.locale, "sv", window.location)}
        />
      </Helmet>
      <div className="bg-white dark:bg-gray-700">
        <Configure hitsPerPage={25} />
        {/* TODO:: Setup bread crumbs to follow the job hierarchy so people can go back */}
        {/*<Breadcrumb*/}
        {/*  attributes={["categories.lvl0", "categories.lvl1", "categories.lvl2"]}*/}
        {/*/>*/}
        {/* TODO:: create sorts so people can sort by common sorts */}
        <div className="w-full px-0 lg:px-12 bg-white dark:bg-gray-700 pt-32 xl:pt-20 m-auto max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-5xl">
          {/*<SortBy*/}
          {/*  items={[*/}
          {/*    { label: 'Featured', value: 'text_search_index' },*/}
          {/*    { label: 'Date Posted (desc )', value: 'text_search_index_posted_date_desc' },*/}
          {/*    { label: 'Application Date (asc)', value: 'text_search_index_application_date_asc' },*/}
          {/*  ]}*/}
          {/*/>*/}
          <SEOText />
          <QuickSearchBanner />
          {/*<button*/}
          {/*  onClick={() => {*/}
          {/*    // TODO:: Make this work to save searches + translations + styling*/}
          {/*    ReactGA.event({*/}
          {/*      category: "interactions",*/}
          {/*      action: "clickSaveSearch",*/}
          {/*      nonInteraction: false, // optional, true/false*/}
          {/*    });*/}
          {/*    // TODO:: Check if this works*/}
          {/*    if (!isSearchSaved) {*/}
          {/*      addToSavedSearches(location.pathname + location.search);*/}
          {/*    } else {*/}
          {/*      deleteSavedSearches(location.pathname + location.search);*/}
          {/*    }*/}
          {/*    setIsSearchSaved(!isSearchSaved);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {isSearchSaved ? "Unsave Search" : "Save Search"}*/}
          {/*</button>*/}
          <div className="ais-InstantSearch w-full bg-white dark:bg-gray-700 m-auto max-w-5xl">
            <div className="text-black dark:text-white pl-4">
              <Stats />
            </div>
            {/* TODO:: Change hit component class so it can show dark and light */}
            <Hits hitComponent={Hit} />
          </div>
          <div className="w-full pt-0 lg:pt-8 pb-8 items-center justify-center ml-0 md:ml-8 lg:ml-16 xl:ml-32">
            {/* TODO:: Find a way to center this */}
            <Pagination padding={isMobile ? 1 : 3} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
