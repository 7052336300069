import React from "react";
import { Helmet } from "react-helmet-async";
import { SearchWrapper } from "../components/SearchWrapper.tsx";
import Footer from "../components/components/Footer.tsx";

const Business: React.FC = () => {
  return (
    <SearchWrapper>
      <div className="max-w-4xl mx-auto p-4 pt-32">
        {/* React Helmet for SEO & Metadata */}
        <Helmet>
          <title>JobCrawls.com | TechTactics Oy</title>
          <meta
            name="description"
            content="Learn about our ethical job platform helping people in Finland find jobs during tough times, owned and operated by TechTactics Oy."
          />
        </Helmet>

        {/* Brand Color Header */}
        <header className="bg-amber-300 text-black py-6 px-4 rounded-md mb-8 text-center">
          <h1 className="text-3xl font-bold">About Our Ethical Platform</h1>
        </header>

        {/* Logo Placeholder */}
        <div className="flex justify-center items-center mb-8">
          <div className="w-48 h-48 bg-gray-200 rounded-md flex items-center justify-center">
            <span className="text-gray-500">
              <img
                src="/logoTechTacticsOy.png"
                alt="TechTactics Oy"
                className="w-32"
              />
            </span>
          </div>
        </div>
        <hr />
        {/* TODO:: Style this and make it send us feedback somewhere */}
        <div>
          We want to hear your feedback, tell us what we should change or add
          <input type="text" />
          <button>Submit</button>
        </div>
        <hr />
        {/* Main Content */}
        <section className="space-y-6 text-gray-800 leading-7">
          <p>
            Welcome to our ethical job website for Finland, proudly owned and
            operated by
            <strong> TechTactics Oy</strong>. Our goal is simple: to help
            individuals find meaningful employment opportunities, especially
            during times of unemployment or career transition.
          </p>

          <p>
            We believe <em>jobs are the most empowering tool</em> for personal
            and social growth. When people are empowered, they become positive
            agents of change in their communities—and we want to see a better
            world for everyone.
          </p>

          <p>
            We are <strong>pro-union</strong> and stand firmly against
            businesses that exploit workers or harm communities. At TechTactics
            Oy, we only partner with organizations that share our vision:
            investing in people, their well-being, and the greater good.
          </p>

          <p>
            Our platform is designed with empathy and accessibility in mind. We
            strive to make the job search process transparent and fair, ensuring
            that every job listed offers genuine opportunities for growth,
            security, and stability.
          </p>

          <p>
            Thank you for trusting us with your job search. We’re excited to be
            part of your journey toward finding meaningful employment—and,
            ultimately,
            <strong> a more empowered future for everyone</strong>.
          </p>
        </section>
      </div>
      <Footer />
    </SearchWrapper>
  );
};

export default Business;
