export function addToSavedJobs(url: string): void {
  const existingJobURLs: string[] =
    JSON.parse(localStorage.getItem("savedJobs") ?? "[]") ?? [];
  // TODO:: Check if this works and add the same to savedJobs
  const newJobsURLsList = new Set(existingJobURLs);
  newJobsURLsList.add(url);
  localStorage.setItem(
    "savedJobs",
    JSON.stringify(Array.from(newJobsURLsList))
  );
}

export function setSavedJobs(jobs: Array<string>) {
  localStorage.setItem("savedJobs", JSON.stringify(jobs));
}

export function deleteSavedJob(url: string): void {
  const existingJobURLs: string[] = JSON.parse(
    localStorage.getItem("savedJobs") ?? "[]"
  );
  // TODO:: Check if this works and add the same to savedJobs
  const newJobsURLsList = new Set(existingJobURLs);
  newJobsURLsList.delete(url);
  localStorage.setItem(
    "savedJobs",
    JSON.stringify(Array.from(newJobsURLsList))
  );
}
