import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { localisedRouteGenerator } from "../../../i18n/routes/routeGenerator";
import { useInstantSearch } from "react-instantsearch";

const Logo = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { indexUiState, setIndexUiState } = useInstantSearch();

  const clearSearch = (): void => {
    // TODO:: make sure this clearing of refinements and queries works
    setIndexUiState((prevIndexUiState) => ({
      ...prevIndexUiState,
      query: "",
      refinementList: {},
    }));
  };

  return (
    <>
      <img
        onClick={() => {
          clearSearch();
          navigate(localisedRouteGenerator(false, intl, "route.home"));
        }}
        className="hidden sm:block cursor-pointer"
        src={"/logo_horizontal.png"}
        height="100"
        width="100"
        alt="Logo"
      />
    </>
  );
};

export default Logo;
