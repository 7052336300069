import { FunctionComponent } from "react";
import { useInstantSearch } from "react-instantsearch";
import ReactGA from "react-ga4";

interface QuickSearchQueryProps {
  text: string;
}

/* Takes-in the facet we want to set and an array of values we want to set for it and updates the search.
 * The children prop is the actual content of the button */
export const QuickSearchQuery: FunctionComponent<QuickSearchQueryProps> = (
  props
) => {
  const { text } = props;
  const { indexUiState, setIndexUiState } = useInstantSearch();

  return (
    <button
      type="button"
      onClick={() => {
        ReactGA.event({
          category: "interactions",
          action: "clickQuickSearchFacet",
          nonInteraction: false, // optional, true/false
        });
        setIndexUiState((prevIndexUiState) => ({
          ...prevIndexUiState,
          query: text,
        }));
      }}
    >
      <span className="rounded-xl bg-amber-300 hover:bg-amber-400 text-black p-3 cursor-pointer text-nowrap border border-amber-800">
        {text}
      </span>
    </button>
  );
};
