import Home from "./Home";
import { Routes, Route } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { localisedRouteGenerator } from "../i18n/routes/routeGenerator";
import ProfileOverview from "./ProfileOverview";
import AuthCallback from "./AuthCallback";
import CoolFinnishTechCompaniesEnglish from "./Blog/CoolFinnishTechCompaniesEnglish";
import Blog from "./Blog";
import SavedJobs from "./SavedJobs.tsx";
import NotFound from "./NotFound";
import React from "react";
import Terms from "./Terms.tsx";
import Privacy from "./Privacy.tsx";
import Business from "./Business.tsx";
import SavedSearches from "./SavedSearches.tsx";

const routeMessages = defineMessages({
  homeRoute: {
    id: "route.home",
    description: "Home page route",
    defaultMessage: "/",
  },
  favouritesRoute: {
    id: "route.savedJobs",
    description: "Where users can view their favourited listings",
    defaultMessage: "/saved-jobs",
  },
  privacyRoute: {
    id: "route.privacy",
    description: "Where users can see the privacy policy",
    defaultMessage: "/privacy",
  },
  termsRoute: {
    id: "route.terms",
    description: "Where users can see the terms of service",
    defaultMessage: "/terms",
  },
  savedSearchesRoute: {
    id: "route.savedSearches",
    description: "Where users can view their previous searches",
    defaultMessage: "/saved-searches",
  },
  businessRoute: {
    id: "route.business",
    description: "Business page route",
    defaultMessage: "/business",
  },
  aboutRoute: {
    id: "route.about",
    description: "About page route",
    defaultMessage: "/about",
  },
  profileOverviewRoute: {
    id: "route.profileOverview",
    description: "All about the logged-in tray",
    defaultMessage: "/user-profile-overview",
  },
  authCallbackRoute: {
    id: "route.authCallback",
    description: "Where the authentication returns to after",
    defaultMessage: "/callback",
  },
  coolFinnishTechCompaniesEnglishRoute: {
    id: "route.coolFinnishTechCompaniesEnglish",
    description:
      "An article about cool Finnish tech companies who work in English",
    defaultMessage: "/blog/cool-finnish-tech-companies-english",
  },
  blog: {
    id: "route.blog",
    description: "An index of all blog articles",
    defaultMessage: "/blog",
  },
});

function RoutesCenter() {
  const intl = useIntl();
  // Because there's already a routes object above handling the locale, we only need the actual path here
  return (
    <Routes>
      <Route
        path={localisedRouteGenerator(true, intl, "route.savedJobs")}
        element={<SavedJobs />}
      />
      <Route
        path={localisedRouteGenerator(true, intl, "route.savedSearches")}
        element={<SavedSearches />}
      />
      <Route
        path={localisedRouteGenerator(
          true,
          intl,
          "route.coolFinnishTechCompaniesEnglish"
        )}
        element={<CoolFinnishTechCompaniesEnglish />}
      />
      <Route
        path={localisedRouteGenerator(true, intl, "route.blog")}
        element={<Blog />}
      />
      <Route
        path={localisedRouteGenerator(true, intl, "route.privacy")}
        element={<Privacy />}
      />
      <Route
        path={localisedRouteGenerator(true, intl, "route.business")}
        element={<Business />}
      />
      <Route
        path={localisedRouteGenerator(true, intl, "route.terms")}
        element={<Terms />}
      />
      <Route
        path={localisedRouteGenerator(true, intl, "route.profileOverview")}
        element={<ProfileOverview />}
      />
      <Route
        path={localisedRouteGenerator(true, intl, "route.authCallback")}
        element={<AuthCallback />}
      />
      <Route path="*" index element={<Home />} />
      <Route path="*" element={<NotFound />} />
      {/*<Route path="*" element={<NoLang />} />*/}
    </Routes>
  );
}

export default RoutesCenter;
